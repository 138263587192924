<template>
  <!--
    The view for the Installation Converter Sections
  -->
  <div class="installationDetail_ConverterSections">
    <template>
      <div class="card">
        <Portlet
          :title="'Sections'"
          icon="skiing"
        >
          <template slot="buttons">
            <button
              class="btn btn-sm btn-primary float-right"
              @click="showSidebarAdd"
            >
              <font-awesome-icon
                class="mr-1"
                icon="plus"
              />
              <span>{{ $t('resortSpotAndPhotopoint.addLift') }}</span>
            </button>
          </template>
          <LoadingPlaceholder v-if="loading" />
          <template v-else>
            <ResortLift
              ref="resortLift"
              :resort-id="installationObject.resortId"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-lift="true"
              :parent-resort-id="installationObject.resortId"
              @reload="reloadLifts"
            />
          </template>
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailConverterSections",
  components: {
    ResortLift: () => import('@/components/Resort/ResortLift.vue'),
    DatabaseAuditLogList: () =>
      import("@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue"),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      loadingErrors: true,
      installationObject: null
    };
  },
  metaInfo () {
    return {
      title: 'Section'
    };
  },
  created () {
    this.getInstallation();
  },
  methods: {
    reloadLifts () {
      this.$refs.resortLift.reloadLifts();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    showSidebarAdd () {
      this.$refs.resortLift.showSidebarAdd();
    },
    getInstallation () {
      this.loading = true;
      this.axios
        .get("/Installation/Get?id=" + this.installationId)
        .then(response => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>